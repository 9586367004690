import React, { Fragment, useState, useCallback } from "react"
import "../styles/layout.scss"
import { Link } from "gatsby"
import Helmet from "react-helmet"
import { StaticImage } from "gatsby-plugin-image"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import useWindowSize from "../utils/useWindowSize"

const metaTitle = "Catsh"
const metaDescription = "Catsh, where money does grows on trees"

export default function Layout({ children }) {
  const [isHamburgerOpen, setIsHamburgerOpen] = useState(false)
  const size = useWindowSize()
  const openMenu = useCallback(() => {
    const body = document.querySelector("body")
    body.style.overflow = "hidden"
    setIsHamburgerOpen(true)
  }, [])
  const closeMenu = useCallback(() => {
    const body = document.querySelector("body")
    body.style.overflow = "auto"
    setIsHamburgerOpen(false)
  }, [])
  return (
    <Fragment>
      <Helmet
        link={[
          {
            rel: "apple-touch-icon",
            href: "/apple-touch-icon.png",
            sizes: "180x180",
          },
          {
            rel: "icon",
            href: "/favicon-32x32.png",
            sizes: "32x32",
            type: "image/png",
          },
          {
            rel: "icon",
            href: "/favicon-16x16.png",
            sizes: "16x16",
            type: "image/png",
          },
          { rel: "manifest", href: "/site.webmanifest" },
          {
            rel: "mask-icon",
            href: "/safari-pinned-tab.svg",
            color: "#5bbad5",
          },
        ]}
      >
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />
        <meta charSet="utf-8" />
        <title>{metaTitle}</title>
        {/* <link rel="canonical" href="" /> */}
        <meta name="description" content={metaDescription} />
        <meta name="og:title" content={metaTitle} />
        <meta name="og:description" content={metaDescription} />
        <meta name="og:type" content="website" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:creator" content={"Hagai Harari YogiHa"} />
        <meta name="twitter:title" content={metaTitle} />
        <meta name="twitter:description" content={metaDescription} />
      </Helmet>
      <div className="nav">
        <Link to="/">
          <StaticImage className="logo" src="../images/logo.png" alt="logo" />
        </Link>
        <div className="nav-btns">
          <AnchorLink className="button" to="/#second-block">
            Catsh App
          </AnchorLink>
          <AnchorLink className="button" to="/#third-block">
            Catsh For Business
          </AnchorLink>
          <AnchorLink className="button" to="/#fifth-block">
            Our Story
          </AnchorLink>
          <a
            href="https://b.catsh.app/index.php/auth/login"
            target="_blank"
            rel="noreferrer"
            className="businessBtn"
          >
            {size.width > 1400 ? `BUSINESS ` : ""}SIGN IN
          </a>
          <span
            className="hamburger-span"
            tabIndex={-1}
            role="button"
            onKeyPress={event => event.key === "Enter" && openMenu}
            onClick={openMenu}
          >
            <StaticImage
              className="hamburger"
              src="../images/hamburger.png"
              alt="hamburger"
            />
          </span>
        </div>
      </div>
      <div className={`mobileMenu ${isHamburgerOpen ? "open" : ""}`}>
        <StaticImage
          onClick={closeMenu}
          className="x"
          src="../images/hamburger-x.png"
          alt="hamburger-x"
        />
        <AnchorLink
          onAnchorLinkClick={closeMenu}
          className="link"
          to="/#second-block"
        >
          Catsh App
        </AnchorLink>
        <AnchorLink
          onAnchorLinkClick={closeMenu}
          className="link"
          to="/#third-block"
        >
          Catsh For Business
        </AnchorLink>
        <AnchorLink
          onAnchorLinkClick={closeMenu}
          className="link"
          to="/#fifth-block"
        >
          Our Story
        </AnchorLink>
        <Link onClick={closeMenu} className="link" to="/support">
          Support
        </Link>
        <Link onClick={closeMenu} className="link" to="/contant">
          Contact Us
        </Link>
        <Link onClick={closeMenu} className="link" to="/privacy">
          Privacy Policy
        </Link>
        <Link onClick={closeMenu} className="link" to="/tos">
          Terms of Use
        </Link>
        <Link onClick={closeMenu} className="link" to="/partners">
          Partners
        </Link>
        <Link onClick={closeMenu} className="link" to="/investors">
          Investors
        </Link>
      </div>

      {children}
      <div className="footer">
        <StaticImage className="logo" src="../images/logo.png" alt="logo" />
        <Link className="link" to="/support">
          Support
        </Link>
        <Link className="link" to="/contant">
          Contact Us
        </Link>
        <Link className="link" to="/privacy">
          Privacy Policy
        </Link>
        <Link className="link" to="/tos">
          Terms of Use
        </Link>
        <Link className="link" to="/partners">
          Partners
        </Link>
        <Link className="link" to="/investors">
          Investors
        </Link>
        <div className="social-div">
          <StaticImage
            className="social"
            src="../images/facebook.png"
            alt="facebook"
          />
          <StaticImage
            className="social"
            src="../images/instegram.png"
            alt="instegram"
          />
          <StaticImage
            className="social"
            src="../images/twitter.png"
            alt="twitter"
          />
        </div>
      </div>
      <div className="mobile-external-footer">
        <Link className="link" to="/support">
          Support
        </Link>
        <Link className="link" to="/contant">
          Contact Us
        </Link>
        <Link className="link" to="/privacy">
          Privacy Policy
        </Link>
        <Link className="link" to="/tos">
          Terms of Use
        </Link>
        <Link className="link" to="/partners">
          Partners
        </Link>
        <Link className="link" to="/investors">
          Investors
        </Link>
      </div>
    </Fragment>
  )
}
