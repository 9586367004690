import { useEffect, useState } from "react"

export default function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: 0,
    height: 0,
  })
  useEffect(() => {
    function handleResize() {
      if (typeof window !== "undefined")
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        })
    }
    if (typeof window !== "undefined")
      window.addEventListener("resize", handleResize)
    handleResize()
    return () => window.removeEventListener("resize", handleResize)
  }, [])
  return windowSize
}
